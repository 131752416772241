


.hero-section { 
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
   
    
    img {
        width: 100%;
        display: block;
     
       
    }
   
}

.content-section {
 margin: 0;
 padding: 0 25px;
 display: flex;
 flex-direction: column;
 align-items: left;
 justify-content: left;
 width: 100%;


 h1 {
  font-size: $big-text;
  font-family: 'Preahvihear', sans-serif;
  font-weight: 1000;
   text-align: left;
   width: 100%;
   color: white;
   
}




   @media(max-width: 767px) { 
    
      position: relative;
      right: -5px;
      z-index: 1;
    
      h1 {
        font-size: 1.2rem;
        font-weight: bolder;
        line-height: 1.3;
        text-align: center;

       
      }

      p {
        font-size: .55rem;
        color: $blue;
        line-height: 1.4;
        margin-top: 0px;
        text-align: center;
        
        
      
      }
 }
   
}





.subheading {
text-align: left;
font-size: $small-text;
font-family: 'Archivo Black', sans-serif;
line-height: 40px;
color: $blue;
width: 100%;

@media( max-width: 767px) {
      font-size: 1rem;
      
}

& .my-icon {
  font-size: 30px;
  padding: 0;
  margin: 0;
  position: relative;
  top: 5px;
  color: $red;

  @media( max-width: 767px) {
   visibility: hidden;
    
}
}
}


.mini-bubble {
width: 60px;
display: flex;
position: relative;
top: 0px;
animation: pulse 2s infinite;

@media(max-width: 767px) {
  visibility: hidden;
  
}

}

@keyframes pulse {
    0% {
      transform: scale(1);
      
    }
    50% {
      transform: scale(1.3);
      
    }
    100% {
      transform: scale(1);
     
    }
  }

  .icon {
    font-size: large;

  
  }

  .container-style {
    background-position:center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;


  
  }
  
  /* For mobile devices */
  @media (max-width: 768px) {
    .container-style {
      background-position: calc(100% - 20%) center;
      background-size:cover;
      opacity: 1;
      height: 60vh;
      z-index: 2;
     
     
      /* Add any other adjustments you need for mobile */



      
    }

   
  }