
.feeding-section {
    height: 100%;
}



.feeding{
    background-color:$blue;

    h1 {
        color: white;
        text-align: center;
      margin: 0;
      padding: 8px;
      font-size: 2rem;

      @media(max-width:768px) {
        font-size: 1.4rem;
        text-align: center;
       }
    }
}

.feeding-hero {
    background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 80vh;
        opacity: 1;

        @media(max-width:768px) {
           height: 40vh;
           }
}





.feeding-header {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 40vh;
   

    h1 {
        color: white;
        text-align: center;
      margin: 0;
      padding: 0;
      font-size: $big-text;

      @media(max-width:768px) {
        font-size: 2rem;
        text-align: center;
       }
    }

    p {
        background-image: linear-gradient(to right, rgb(132, 167, 250), $red);
       color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        font-size: 2rem;

        @media(max-width:768px) {
            font-size: 1rem;
            text-align: center;
           }
    }
}




.feeding-wrapper {
    display: flex;
    flex-direction: column; /* Horizontal layout */
    align-items: center; /* Center items vertically */
    min-height: 100vh;
    justify-content: center;
   padding: 20px;


   @media (max-width: 768px){
    
      padding: 20px;
   };
    
  }

 

  .feeding-content {
   background-color: $blue;
    width: 85%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
 
   
    @media(max-width:768px) {
        padding-top: 15px;
    
       }

   h1 {
   font-size: 2rem;


   @media(max-width:768px) {
    font-size: 1.2rem;
    text-align: center;
    
   }
}

    p {
        font-family: 'Open Sans', sans-serif;
        text-align: center;

        @media(max-width:768px) {
            font-size: 0.7rem;
            text-align: center;
           }

}
    h1, p {
        padding: 1rem 3rem;
        color: white;
    }


   }

   .feeding-content-two {
    background-color: $red;
    width: 85%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;

    @media(max-width:768px) {
        max-height: 60%;
       }
    
    h1 {
        text-align: center;
        font-size: 2rem;
        padding: 0.5rem 3rem;
    
        color: white;

        @media(max-width:768px) {
            font-size: 1.5rem;
            text-align: center;
           }
    }

    p {
        color: white;
       
       padding: 1rem 2rem;
       font-family: 'Open Sans', sans-serif;

       @media(max-width:768px) {
        font-size: 0.7rem;
        text-align: center;
       }
       
    }

    ul {
        
        color: white;
        font-family: 'Open Sans', sans-serif;
       display: flex;
       flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem 2rem;

      @media(max-width:768px) {
        font-size: 0.8rem;
        text-align: center;
       }
    }

   }


   
