
$blue: #0B3E6C;
$red: #ff5555;

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
  }

 