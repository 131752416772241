@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";


.testimonial-page-section {
  margin: 0;
  height: 60vh;


  @media(max-width: 768px) {
   height: 55vh;
   }

  h2 {
    padding: 20px;

    @media(max-width: 768px) {
      font-size: 1.2rem;
     }

  }

 
}

.review-header {
  background-color:  $blue;
 width: 100%;
  margin: 0;
  padding: 0;
  color: white;
  
}

/* Ensure flexbox layout for the review-wrapper */
.testimonial-review-wrapper {
  display: flex;
  align-items: center; /* Vertically center the buttons with the slider */
  justify-content: center; /* Horizontally center everything */
  gap: 5px; /* Space between child elements */
   height: 100%;
  @media(max-width: 768px) {
    padding: 0.5rem 1rem;
   
  }

}


.review {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 768px) {
    padding: 1px;
   
  }
}



/* Slider to take the maximum width available, minus the space for buttons */
.review-slider {
  flex-grow: 1;
}

/* If you wrap the slider in a div, ensure no styles interfere with its visibility */
.testimonial-slider-container {
  
    height: 300px;
  flex-grow: 1; /* Let it grow to take up available space */
  overflow: hidden; /* Hide overflowed content, but ensure this doesn't clip anything unintentionally */
  width: 50%;
   
  p {
    color: rgb(98, 98, 98);
     padding: 0 1rem;
    @media(max-width: 768px) {
    font-size: 0.62rem;
   
  }
}
}

/* Buttons will take only the space they need */
.prev-btn, .next-btn {
  flex-shrink: 0;
  padding: 8px 5px; /* Sample padding for buttons */
  color: $red; /* Button text/icon color */
  border: none; /* Remove default borders */
  cursor: pointer; /* Indicate clickability */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition for hover effects */
  background-color: transparent !important;

  @media(max-width: 768px) {
    background-color: transparent !important;

}
}

.btn-1 {
  font-size: 15px;
}

.btn-2 {
  font-size: 15px;
}

.prev-btn:hover, .next-btn:hover {
   color: $blue; /* Darken button on hover for feedback */
}

.prev-btn, .next-btn, .slider-container {
  min-width: 0;
}

/* Styling for the testimonials */


.review-header {
  text-align: center;
  margin-bottom: 20px;
 
}

.review-header-box {
  display: flex;
  align-items: center;
  
}

.stars-icon {
  color: $red; /* Color for star icons */
}

