@import url("https://fonts.googleapis.com/css2?family=Preahvihear&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Preahvihear&display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.navbar {
  display: flex;
  justify-content: space-around; /* This will put the logo and menu at opposite ends */
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgb(235, 235, 235);
}

.logo {
  height: 11rem;
  width: auto;
}
@media (max-width: 1024px) {
  .logo {
    height: 8rem;
  }
}

.logo-section {
  display: flex;
}

.menu ul {
  display: flex; /* This will make the list items horizontal */
  list-style-type: none; /* This will remove bullet points */
  padding: 0; /* This will remove padding */
  margin: 0; /* This will remove margin */
}

.menu a {
  margin-right: 20px; /* This adds space between the menu items */
  font-family: "Preahvihear", sans-serif;
}
@media (max-width: 1024px) {
  .menu a {
    margin: 0;
    justify-content: space-between;
  }
}

/* You can also add styles to make the list items look more like links */
.menu a {
  cursor: pointer;
  color: #0B3E6C; /* Example link color */
  text-decoration: none;
  font-size: 1.3rem;
}
@media (max-width: 767px) {
  .menu a {
    color: white;
  }
}

.menu a:hover {
  text-decoration: underline;
  color: #ff5555;
}

nav {
  display: flex;
  align-items: center;
  height: 100px;
}
nav img {
  position: relative;
  left: 330px;
  bottom: 80px;
}
@media (max-width: 1300px) {
  nav img {
    display: none;
  }
}

@media (min-width: 767px) {
  .hamburger-menu {
    visibility: hidden;
  }
  .nav-close-btn {
    visibility: hidden;
  }
}
@media only screen and (max-width: 767px) {
  nav.responsive_nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background-color: #0B3E6C;
    gap: 1.5rem;
    transition: 1s;
    transform: translateY(0);
  }
  .hamburger-menu {
    color: #0B3E6C;
    border: none;
    background-color: white;
    border-radius: 20px;
  }
  nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: #0B3E6C;
    gap: 1.5rem;
    transition: 1s;
    transform: translateY(-100vh);
  }
  .responsive_nav {
    transform: translateY(0);
  }
  .nav-close-btn {
    border: none;
    background-color: #0B3E6C;
    color: white;
  }
}
.home-btn {
  display: none;
}

.responsive_nav .home-btn {
  display: block;
}

.menu-item {
  position: relative;
}

.dropdown {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: none;
}

.menu-item:hover .dropdown {
  display: block;
}

.dropdown a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 1rem;
}

.dropdown a:hover {
  background-color: #ddd;
  text-decoration: none;
}

.hero-section {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hero-section img {
  width: 100%;
  display: block;
}

.content-section {
  margin: 0;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  width: 100%;
}
.content-section h1 {
  font-size: 3.5rem;
  font-family: "Preahvihear", sans-serif;
  font-weight: 1000;
  text-align: left;
  width: 100%;
  color: white;
}
@media (max-width: 767px) {
  .content-section {
    position: relative;
    right: -5px;
    z-index: 1;
  }
  .content-section h1 {
    font-size: 1.2rem;
    font-weight: bolder;
    line-height: 1.3;
    text-align: center;
  }
  .content-section p {
    font-size: 0.55rem;
    color: #0B3E6C;
    line-height: 1.4;
    margin-top: 0px;
    text-align: center;
  }
}

.subheading {
  text-align: left;
  font-size: 1.3rem;
  font-family: "Archivo Black", sans-serif;
  line-height: 40px;
  color: #0B3E6C;
  width: 100%;
}
@media (max-width: 767px) {
  .subheading {
    font-size: 1rem;
  }
}
.subheading .my-icon {
  font-size: 30px;
  padding: 0;
  margin: 0;
  position: relative;
  top: 5px;
  color: #ff5555;
}
@media (max-width: 767px) {
  .subheading .my-icon {
    visibility: hidden;
  }
}

.mini-bubble {
  width: 60px;
  display: flex;
  position: relative;
  top: 0px;
  animation: pulse 2s infinite;
}
@media (max-width: 767px) {
  .mini-bubble {
    visibility: hidden;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
.icon {
  font-size: large;
}

.container-style {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  color: #0B3E6C;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* For mobile devices */
@media (max-width: 768px) {
  .container-style {
    background-position: 80% center;
    background-size: cover;
    opacity: 1;
    height: 60vh;
    z-index: 2;
    /* Add any other adjustments you need for mobile */
  }
}
.service-section {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0B3E6C;
  padding: 30px;
}
@media (max-width: 767px) {
  .service-section {
    padding: 1px;
  }
}

.service-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80%;
  border-radius: 10%;
}
@media (max-width: 767px) {
  .service-container {
    width: 100%;
  }
}

.services {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
@media (max-width: 767px) {
  .services {
    gap: 10px;
  }
}
.services img {
  width: 250px;
  transition: transform 0.3s ease;
}
@media (max-width: 767px) {
  .services img {
    width: 120px;
  }
}
.services img:hover {
  transform: scale(1.1);
}

.aboutus-section {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  gap: 30px;
  opacity: 1;
}
@media (max-width: 768px) {
  .aboutus-section {
    height: 75vh;
    gap: 5px;
  }
}

.about-us {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.avatar {
  position: relative;
  top: 50px;
  z-index: 2;
}

img {
  width: 230px;
}
@media screen and (max-width: 768px) {
  img {
    width: 200px;
  }
}

.image-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 60%;
}

.container {
  display: flex;
  flex-direction: column;
  border: 6px solid #0B3E6C;
  position: relative;
  width: 200%;
  text-align: center;
  padding: 15px;
  border-radius: 50px;
  background-color: rgb(233, 233, 233);
}
@media screen and (max-width: 768px) {
  .container {
    padding: 0;
    height: 240px;
    top: -80px;
    width: 350px;
  }
}
.container h1 {
  padding: 5px;
  font-size: 2rem;
  color: #0B3E6C;
  width: 100%;
  position: relative;
  bottom: 20px;
}
@media screen and (max-width: 768px) {
  .container h1 {
    padding: 0;
    font-size: 20px;
    position: relative;
    top: 110px;
  }
}
.container p {
  color: rgb(83, 83, 83);
  padding: 1rem 2rem;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .container p {
    padding: 20px;
    font-size: 14px;
  }
}
.container .title {
  font-size: 0.9rem;
  color: #ff5555;
  text-align: center;
  position: relative;
  top: 20px;
  font-family: "Preahvihear", sans-serif;
}
@media screen and (max-width: 768px) {
  .container .title {
    padding: 0;
    font-size: 10px;
    top: 120px;
  }
}

.btn-about {
  border-radius: 20px;
  padding: 10px;
  border: none;
  position: relative;
  top: -30px;
  margin-right: 1300px;
  font-size: 1.3rem;
  background-color: #ff5555;
  color: rgb(255, 255, 255);
  transition: transform 0.3s ease-in-out;
}
@media (max-width: 768px) {
  .btn-about {
    top: -120px;
    margin-left: 780px;
    font-size: 4px;
  }
}
.btn-about:hover {
  transform: scale(1.2);
}

.about-section {
  height: 100vh;
}

.about-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
}
@media screen and (max-width: 768px) {
  .about-container {
    background-size: contain;
    background-repeat: no-repeat;
  }
}

h1 {
  font-size: 3.5rem;
}

p {
  text-align: center;
  font-size: 1.3rem;
}

img {
  width: 230px;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  img {
    width: 200px;
  }
}

.image-area {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 60%;
}

.text-container {
  display: grid;
  flex-direction: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  border: 6px solid #0B3E6C;
  position: relative;
  align-items: center;
  top: -100px;
  width: 100%;
  text-align: center;
  padding: 15px;
  border-radius: 50px;
  background-color: white;
}
@media screen and (max-width: 768px) {
  .text-container {
    padding: 0;
    height: 480px;
    top: -80px;
    width: 340px;
  }
}
.text-container .item-1 {
  position: relative;
  grid-column: 1/2; /* This means the item starts at the first vertical grid line and ends before the second */
  grid-row: 1/2;
  font-size: 0.9rem;
  color: #ff5555;
}
@media screen and (max-width: 768px) {
  .text-container .item-1 {
    padding-left: 18px;
    font-size: 10px;
    top: 50px;
  }
}
.text-container .item-2 {
  padding: 5px;
  font-size: 2rem;
  grid-column: 2/3; /* This places the item in the second column */
  grid-row: 1/2;
}
@media screen and (max-width: 768px) {
  .text-container .item-2 {
    padding: 0;
    font-size: 15px;
    position: relative;
    top: 50px;
    grid-row: 2/1;
  }
}
.text-container .item-3 {
  color: rgb(83, 83, 83);
  padding: 1rem 3rem;
  text-align: justify;
  grid-column: 1/3; /* This stretches the item across both columns */
  grid-row: 2/3;
}
@media screen and (max-width: 768px) {
  .text-container .item-3 {
    font-size: 10px;
    text-align: center;
  }
}

.about-us {
  line-break: normal;
}

.btn-about {
  width: 220px;
  border-radius: 20px;
  padding: 7px;
  border: none;
  position: relative;
  left: 43%;
  bottom: 130px;
  font-size: 2rem;
  background-color: #ff5555;
  color: white;
  transition: transform 0.3s ease-in-out;
}
@media screen and (max-width: 768px) {
  .btn-about {
    left: 260px;
    bottom: 110px;
  }
}
.btn-about:hover {
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr; /* Single column layout */
    gap: 5px; /* Adjust spacing if necessary */
    padding: 10px; /* Add padding around the container */
  }
  .item-1, .item-2, .item-3 {
    grid-column: 1/2; /* All items occupy the single column */
    grid-row: auto; /* Allow items to naturally stack */
  }
}
.testimonial-section {
  margin: 0;
  height: 45vh;
}
@media (max-width: 768px) {
  .testimonial-section {
    height: 52vh;
  }
}
.testimonial-section h2 {
  padding: 20px;
}
@media (max-width: 768px) {
  .testimonial-section h2 {
    font-size: 1.2rem;
  }
}

.review-header {
  background-color: #0B3E6C;
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;
}

/* Ensure flexbox layout for the review-wrapper */
.review-wrapper {
  display: flex;
  align-items: center; /* Vertically center the buttons with the slider */
  justify-content: center; /* Horizontally center everything */
  gap: 5px; /* Space between child elements */
}
@media (max-width: 768px) {
  .review-wrapper {
    padding: 0.5rem 1rem;
    height: 100%;
  }
}

.review {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .review {
    padding: 1px;
  }
}

/* Slider to take the maximum width available, minus the space for buttons */
.review-slider {
  flex-grow: 1;
}

/* If you wrap the slider in a div, ensure no styles interfere with its visibility */
.slider-container {
  height: 300px;
  flex-grow: 1; /* Let it grow to take up available space */
  overflow: hidden; /* Hide overflowed content, but ensure this doesn't clip anything unintentionally */
  padding: 0px 1.2rem;
}
.slider-container p {
  color: rgb(98, 98, 98);
}
@media (max-width: 768px) {
  .slider-container p {
    font-size: 0.62rem;
  }
}

/* Buttons will take only the space they need */
.prev-btn, .next-btn {
  flex-shrink: 0;
  padding: 8px 5px; /* Sample padding for buttons */
  color: #ff5555; /* Button text/icon color */
  border: none; /* Remove default borders */
  cursor: pointer; /* Indicate clickability */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition for hover effects */
  background-color: transparent !important;
}
@media (max-width: 768px) {
  .prev-btn, .next-btn {
    background-color: transparent !important;
  }
}

.btn-1 {
  font-size: 15px;
}

.btn-2 {
  font-size: 15px;
}

.prev-btn:hover, .next-btn:hover {
  color: #0B3E6C; /* Darken button on hover for feedback */
}

.prev-btn, .next-btn, .slider-container {
  min-width: 0;
}

/* Styling for the testimonials */
.review-header {
  text-align: center;
  margin-bottom: 20px;
}

.review-header-box {
  display: flex;
  align-items: center;
}

.stars-icon {
  color: #ff5555; /* Color for star icons */
}

.testimonial-page-section {
  margin: 0;
  height: 60vh;
}
@media (max-width: 768px) {
  .testimonial-page-section {
    height: 55vh;
  }
}
.testimonial-page-section h2 {
  padding: 20px;
}
@media (max-width: 768px) {
  .testimonial-page-section h2 {
    font-size: 1.2rem;
  }
}

.review-header {
  background-color: #0B3E6C;
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;
}

/* Ensure flexbox layout for the review-wrapper */
.testimonial-review-wrapper {
  display: flex;
  align-items: center; /* Vertically center the buttons with the slider */
  justify-content: center; /* Horizontally center everything */
  gap: 5px; /* Space between child elements */
  height: 100%;
}
@media (max-width: 768px) {
  .testimonial-review-wrapper {
    padding: 0.5rem 1rem;
  }
}

.review {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .review {
    padding: 1px;
  }
}

/* Slider to take the maximum width available, minus the space for buttons */
.review-slider {
  flex-grow: 1;
}

/* If you wrap the slider in a div, ensure no styles interfere with its visibility */
.testimonial-slider-container {
  height: 300px;
  flex-grow: 1; /* Let it grow to take up available space */
  overflow: hidden; /* Hide overflowed content, but ensure this doesn't clip anything unintentionally */
  width: 50%;
}
.testimonial-slider-container p {
  color: rgb(98, 98, 98);
  padding: 0 1rem;
}
@media (max-width: 768px) {
  .testimonial-slider-container p {
    font-size: 0.62rem;
  }
}

/* Buttons will take only the space they need */
.prev-btn, .next-btn {
  flex-shrink: 0;
  padding: 8px 5px; /* Sample padding for buttons */
  color: #ff5555; /* Button text/icon color */
  border: none; /* Remove default borders */
  cursor: pointer; /* Indicate clickability */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition for hover effects */
  background-color: transparent !important;
}
@media (max-width: 768px) {
  .prev-btn, .next-btn {
    background-color: transparent !important;
  }
}

.btn-1 {
  font-size: 15px;
}

.btn-2 {
  font-size: 15px;
}

.prev-btn:hover, .next-btn:hover {
  color: #0B3E6C; /* Darken button on hover for feedback */
}

.prev-btn, .next-btn, .slider-container {
  min-width: 0;
}

/* Styling for the testimonials */
.review-header {
  text-align: center;
  margin-bottom: 20px;
}

.review-header-box {
  display: flex;
  align-items: center;
}

.stars-icon {
  color: #ff5555; /* Color for star icons */
}

.services-container {
  width: 100%;
  height: 80vh;
  background-color: rgb(247, 247, 247);
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
}
@media (max-width: 767px) {
  .content-container {
    padding-top: 10%;
  }
}

.speech-banner {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-family: "Preahvihear", sans-serif;
  color: white;
  font-size: 2rem;
}
.speech-banner a {
  text-decoration: none;
  color: white;
}
.speech-banner:hover {
  opacity: 70%;
  font-size: 2.5rem;
}
@media (max-width: 767px) {
  .speech-banner:hover {
    font-size: 1.5rem;
  }
}
@media (max-width: 767px) {
  .speech-banner {
    font-size: 1.2rem;
  }
}

.feeding-banner {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Preahvihear", sans-serif;
  color: white;
  font-size: 2rem;
}
.feeding-banner a {
  text-decoration: none;
  color: white;
}
.feeding-banner:hover {
  opacity: 70%;
  font-size: 2.5rem;
}
@media (max-width: 767px) {
  .feeding-banner:hover {
    font-size: 1.5rem;
  }
}
@media (max-width: 767px) {
  .feeding-banner {
    font-size: 1.2rem;
  }
}

.myo-banner {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Preahvihear", sans-serif;
  color: white;
  font-size: 2rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.myo-banner a {
  text-decoration: none;
  color: white;
}
.myo-banner:hover {
  opacity: 70%;
  font-size: 2.5rem;
}
@media (max-width: 767px) {
  .myo-banner:hover {
    font-size: 1.5rem;
  }
}
@media (max-width: 767px) {
  .myo-banner {
    font-size: 1.2rem;
  }
}

.banner-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 20%;
  width: 60%;
}
@media (max-width: 767px) {
  .banner-link {
    width: 100%;
  }
}

.feeding-section {
  height: 100%;
}

.feeding {
  background-color: #0B3E6C;
}
.feeding h1 {
  color: white;
  text-align: center;
  margin: 0;
  padding: 8px;
  font-size: 2rem;
}
@media (max-width: 768px) {
  .feeding h1 {
    font-size: 1.4rem;
    text-align: center;
  }
}

.feeding-hero {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 80vh;
  opacity: 1;
}
@media (max-width: 768px) {
  .feeding-hero {
    height: 40vh;
  }
}

.feeding-header {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 40vh;
}
.feeding-header h1 {
  color: white;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 3.5rem;
}
@media (max-width: 768px) {
  .feeding-header h1 {
    font-size: 2rem;
    text-align: center;
  }
}
.feeding-header p {
  background-image: linear-gradient(to right, rgb(132, 167, 250), #ff5555);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 2rem;
}
@media (max-width: 768px) {
  .feeding-header p {
    font-size: 1rem;
    text-align: center;
  }
}

.feeding-wrapper {
  display: flex;
  flex-direction: column; /* Horizontal layout */
  align-items: center; /* Center items vertically */
  min-height: 100vh;
  justify-content: center;
  padding: 20px;
}
@media (max-width: 768px) {
  .feeding-wrapper {
    padding: 20px;
  }
}

.feeding-content {
  background-color: #0B3E6C;
  width: 85%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 768px) {
  .feeding-content {
    padding-top: 15px;
  }
}
.feeding-content h1 {
  font-size: 2rem;
}
@media (max-width: 768px) {
  .feeding-content h1 {
    font-size: 1.2rem;
    text-align: center;
  }
}
.feeding-content p {
  font-family: "Open Sans", sans-serif;
  text-align: center;
}
@media (max-width: 768px) {
  .feeding-content p {
    font-size: 0.7rem;
    text-align: center;
  }
}
.feeding-content h1, .feeding-content p {
  padding: 1rem 3rem;
  color: white;
}

.feeding-content-two {
  background-color: #ff5555;
  width: 85%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
}
@media (max-width: 768px) {
  .feeding-content-two {
    max-height: 60%;
  }
}
.feeding-content-two h1 {
  text-align: center;
  font-size: 2rem;
  padding: 0.5rem 3rem;
  color: white;
}
@media (max-width: 768px) {
  .feeding-content-two h1 {
    font-size: 1.5rem;
    text-align: center;
  }
}
.feeding-content-two p {
  color: white;
  padding: 1rem 2rem;
  font-family: "Open Sans", sans-serif;
}
@media (max-width: 768px) {
  .feeding-content-two p {
    font-size: 0.7rem;
    text-align: center;
  }
}
.feeding-content-two ul {
  color: white;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
}
@media (max-width: 768px) {
  .feeding-content-two ul {
    font-size: 0.8rem;
    text-align: center;
  }
}

.speech-section {
  height: 100%;
}

.speech {
  background-color: #0B3E6C;
}
.speech h1 {
  color: white;
  text-align: center;
  margin: 0;
  padding: 8px;
  font-size: 2rem;
}
@media (max-width: 768px) {
  .speech h1 {
    font-size: 1.4rem;
    text-align: center;
  }
}

.speech-hero {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
  opacity: 1;
}
@media (max-width: 768px) {
  .speech-hero {
    min-height: auto;
  }
}

.speech-header {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 40vh;
}
.speech-header h1 {
  color: white;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 3.5rem;
}
@media (max-width: 768px) {
  .speech-header h1 {
    font-size: 2rem;
    text-align: center;
  }
}
.speech-header p {
  background-image: linear-gradient(to right, rgb(132, 167, 250), #ff5555);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 2rem;
}
@media (max-width: 768px) {
  .speech-header p {
    font-size: 1rem;
    text-align: center;
  }
}

.speech-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  height: 100%;
}
@media (max-width: 768px) {
  .speech-wrapper {
    padding: 30px;
  }
}
.speech-wrapper .speech-content {
  background-color: #0B3E6C;
  width: 80%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 768px) {
  .speech-wrapper .speech-content {
    margin-bottom: 20px;
  }
}
.speech-wrapper .speech-content h1 {
  font-size: 2rem;
}
@media (max-width: 768px) {
  .speech-wrapper .speech-content h1 {
    font-size: 1.5rem;
    text-align: center;
  }
}
.speech-wrapper .speech-content p {
  font-family: "Open Sans", sans-serif;
  text-align: center;
}
@media (max-width: 768px) {
  .speech-wrapper .speech-content p {
    font-size: 0.7rem;
    text-align: center;
  }
}
.speech-wrapper .speech-content h1, .speech-wrapper .speech-content p {
  padding: 1rem 3rem;
  color: white;
}
.speech-wrapper .speech-content-two {
  background-color: #ff5555;
  width: 80%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
}
.speech-wrapper .speech-content-two h1 {
  text-align: center;
  font-size: 2rem;
  padding: 1rem 3rem;
  color: rgb(255, 255, 255);
}
@media (max-width: 768px) {
  .speech-wrapper .speech-content-two h1 {
    font-size: 1.5rem;
    text-align: center;
  }
}
.speech-wrapper .speech-content-two p {
  color: white;
  padding: 1rem 2rem;
  font-family: "Open Sans", sans-serif;
}
@media (max-width: 768px) {
  .speech-wrapper .speech-content-two p {
    font-size: 0.6rem;
    text-align: center;
  }
}
.speech-wrapper .speech-content-two ul {
  color: white;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 2rem;
}
@media (max-width: 768px) {
  .speech-wrapper .speech-content-two ul {
    font-size: 0.7rem;
    text-align: center;
  }
}

.myo-section {
  min-height: 100%;
}

.myo {
  background-color: #0B3E6C;
}
.myo h1 {
  color: white;
  text-align: center;
  margin: 0;
  padding: 8px;
  font-size: 2rem;
}
@media (max-width: 768px) {
  .myo h1 {
    font-size: 1.4rem;
    text-align: center;
  }
}

.myo-hero {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 80vh;
  opacity: 1;
}
@media (max-width: 768px) {
  .myo-hero {
    min-height: 40vh;
  }
}

.myo-header {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 40vh;
}
.myo-header h1 {
  color: white;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 3.5rem;
}
@media (max-width: 768px) {
  .myo-header h1 {
    font-size: 2rem;
    text-align: center;
  }
}
.myo-header p {
  background-image: linear-gradient(to right, rgb(132, 167, 250), #ff5555);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 2rem;
}
@media (max-width: 768px) {
  .myo-header p {
    font-size: 1rem;
    text-align: center;
  }
}

.myo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  min-height: 100vh;
}
@media (max-width: 768px) {
  .myo-wrapper {
    padding: 20px;
  }
}

.myo-content {
  background-color: #0B3E6C;
  width: 80%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 768px) {
  .myo-content {
    margin-bottom: 20px;
  }
}
.myo-content h1 {
  font-size: 2rem;
}
@media (max-width: 768px) {
  .myo-content h1 {
    font-size: 1.5rem;
    text-align: center;
  }
}
.myo-content p {
  font-family: "Open Sans", sans-serif;
  text-align: center;
}
@media (max-width: 768px) {
  .myo-content p {
    font-size: 0.7rem;
    text-align: center;
  }
}
.myo-content h1, .myo-content p {
  padding: 1rem 3rem;
  color: white;
}

.myo-content-two {
  background-color: #ff5555;
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
}
.myo-content-two h1 {
  text-align: center;
  font-size: 2rem;
  padding: 1rem 3rem;
  color: white;
}
@media (max-width: 768px) {
  .myo-content-two h1 {
    font-size: 1.5rem;
    text-align: center;
  }
}
.myo-content-two p {
  color: white;
  padding: 1rem 2rem;
  font-family: "Open Sans", sans-serif;
}
@media (max-width: 768px) {
  .myo-content-two p {
    font-size: 0.7rem;
    text-align: center;
  }
}
.myo-content-two ul {
  color: white;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
}
@media (max-width: 768px) {
  .myo-content-two ul {
    font-size: 0.8rem;
    text-align: center;
  }
}

.span-email {
  color: #ff5555;
}

.contactpage-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: white;
  background-color: #0B3E6C;
}
@media (max-width: 767px) {
  .contactpage-container {
    height: 83.6vh;
  }
}
.contactpage-container p {
  color: white;
}
@media (max-width: 767px) {
  .contactpage-container p {
    font-size: 1rem;
  }
}
@media (max-width: 767px) {
  .contactpage-container h1 {
    font-size: 2rem;
  }
}

.contactpage-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  margin: 0;
}
.contactpage-box p {
  font-size: 1.5rem;
}
.contactpage-box .contact-page-number {
  color: #ff5555;
}
@media (max-width: 767px) {
  .contactpage-box .contact-page-number {
    font-size: 1rem;
    width: 100%;
    margin: 2px;
  }
}
.contactpage-box .contact-page-email {
  color: #ff5555;
}
@media (max-width: 767px) {
  .contactpage-box .contact-page-email {
    font-size: 1rem;
    width: 100%;
    margin: 2px;
  }
}

.contact-page-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
  padding: 0 1rem;
}
.insurance {
  padding: 0 5rem;
}
@media (max-width: 767px) {
  .insurance {
    padding: 0 1rem;
  }
}

.email-icon {
  font-size: 30px;
  position: relative;
  top: 6px;
  color: #ff5555;
  margin-top: 0;
}
@media (max-width: 767px) {
  .email-icon {
    font-size: 20px;
  }
}

.phone-icon {
  font-size: 30px;
  position: relative;
  top: 6px;
  color: #ff5555;
}
@media (max-width: 767px) {
  .phone-icon {
    font-size: 20px;
  }
}

.contact-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  color: white;
  background-color: #0B3E6C;
}
@media (max-width: 767px) {
  .contact-section {
    height: 30vh;
  }
}
.contact-section p {
  color: white;
}
@media (max-width: 767px) {
  .contact-section p {
    font-size: 1rem;
  }
}
@media (max-width: 767px) {
  .contact-section h1 {
    font-size: 2rem;
  }
}

.contact-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  margin: 0;
}
.contact-box p {
  font-size: 1.5rem;
}
.contact-box .contact-info {
  color: #ff5555;
}
@media (max-width: 767px) {
  .contact-box .contact-info {
    font-size: 1rem;
    margin: 2px;
  }
}

.contact-us-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0.2;
}
@media (max-width: 767px) {
  .contact-us-box {
    white-space: none;
  }
}

.email {
  font-size: 30px;
  position: relative;
  top: 6px;
  color: #ff5555;
  margin-top: 0;
}

.phone {
  font-size: 30px;
  position: relative;
  top: 6px;
  color: #ff5555;
}

.copyright {
  text-align: center;
  font-size: 12px;
  color: rgb(199, 199, 199);
  position: relative;
  background-color: #0B3E6C;
  padding: 0;
  margin: 0;
  padding-top: 10px;
  font-family: "Preahvihear", sans-serif;
}

