

.about-section {
    height: 100vh;

   
}

.about-container {
    display: flex;
    align-items: center;
    flex-direction: column;
   padding-top: 40px;
  
   

   @media screen and (max-width: 768px) {
    background-size: contain;
    background-repeat: no-repeat;
   }

    

 
}




h1 {
   font-size: $big-text;

   
}

p {
    
    text-align: center;
    font-size: $small-text;
    
   
    
}



img {
    width: 230px;
    z-index: 1;

    @media screen and (max-width: 768px) {
       width: 200px;
      }
}

.image-area {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 60%;
}

.text-container {
    display: grid;
    flex-direction: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    border: 6px solid $blue;
    position: relative;
    align-items: center;
    top: -100px;
    width: 100%;
    text-align: center;
    padding: 15px;
    border-radius: 50px;
    background-color: white;


    @media screen and (max-width: 768px) {
        padding: 0;
        height: 480px;
        top: -80px;
        width: 340px;
      }

       .item-1 {
        position: relative;
        grid-column: 1 / 2;  /* This means the item starts at the first vertical grid line and ends before the second */
        grid-row: 1 / 2;
         font-size: 0.9rem;
         color: $red;
 
         @media screen and (max-width: 768px) {
             padding-left: 18px;
            font-size: 10px;
            top: 50px;
           }
     }

    .item-2 {
        padding: 5px;
        font-size: 2rem;
        grid-column: 2 / 3;  /* This places the item in the second column */
        grid-row: 1 / 2;  

        @media screen and (max-width: 768px) {
            padding: 0;
           font-size: 15px;
           position: relative;
           top: 50px;
           grid-row: 2 / 1; 
           
          }
    }

    .item-3 {
       color: rgb(83, 83, 83);
      padding: 1rem 3rem;
      text-align: justify;
      grid-column: 1 / 3;  /* This stretches the item across both columns */
      grid-row: 2 / 3;

      @media screen and (max-width: 768px) {
        
       
       font-size: 10px;
       text-align: center;
       
      }
    }

    
}



.about-us {
   line-break: normal;
}


.btn-about {
    width: 220px;
    border-radius: 20px;
    padding: 7px;
    border: none;
    position: relative;
    left: 43%;
    bottom: 130px;
    font-size: 2rem;
    background-color: $red;
    color: white;
    transition: transform 0.3s ease-in-out;
   

    @media screen and (max-width: 768px) {
       left: 260px;
       bottom: 110px;
      }

    &:hover {
        transform: scale(1.2);
    }
}


@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: 1fr; /* Single column layout */
        gap: 5px; /* Adjust spacing if necessary */
        padding: 10px; /* Add padding around the container */
    }

     .item-1, .item-2, .item-3 {
        grid-column: 1 / 2; /* All items occupy the single column */
        grid-row: auto; /* Allow items to naturally stack */
    }
}