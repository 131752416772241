

.service-section {

    width: 100%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    padding: 30px;

    @media(max-width: 767px) {
        padding: 1px;
         
     }
}

.service-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
    // border: solid 1px $blue;
    border-radius: 10%;
    
    @media(max-width: 767px) {
       width: 100%;
         
     }

}




.services {
    display: flex;
    justify-content: space-around;
    
    width: 100%;
  
    @media(max-width: 767px) {
       gap: 10px;
       
       
       
    }
   


    img {

        width: 250px;

   
    transition: transform 0.3s ease; // Smooth transition

    @media(max-width: 767px) {
        width: 120px;
       
    }

    &:hover {
        transform: scale(1.1); // 10% largers
        
    
    }
}
}


