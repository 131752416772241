

.contact-section {

    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    color: white;
    background-color: $blue;


    @media(max-width:767px) {
      height: 30vh;
}

    p {
        color: white;

        @media(max-width:767px) {
          font-size: 1rem;
    }
  }

  h1 {
    @media(max-width:767px) {
      font-size: 2rem;
}
  }
}

.contact-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    margin: 0;

  

  p {
    font-size: 1.5rem;
  }

  .contact-info {
    color: $red;

    @media(max-width:767px) {
      font-size: 1rem;
      
      margin: 2px;
}
    
  }
}


.contact-us-box {
display: flex;
flex-direction: column;
align-items: center;
line-height: 0.2;

@media(max-width:767px) {
white-space: none;
}

}

.email {
    font-size: 30px;
   position: relative;
   top: 6px;
   color: $red;
   margin-top: 0;

}

.phone {
    font-size: 30px;
    position: relative;
    top: 6px;
    color: $red;

    
}

.copyright {
    text-align: center;
  font-size: 12px;
  color: rgb(199, 199, 199);
  position: relative;
  background-color: $blue;
  padding: 0;
  margin: 0;
  padding-top: 10px;
  font-family: 'Preahvihear', sans-serif;
}


