

.aboutus-section {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    gap: 30px;
    opacity: 1;
    
    @media(max-width:768px) {
    height: 75vh;
    gap: 5px;
    }

}

.about-us {
    display: flex;
    align-items: center;
    flex-direction: column;
   justify-content: center;

  
}



.avatar {
    position: relative;
    top: 50px;
    z-index: 2;
}




img {
    width: 230px;
   
    

    @media screen and (max-width: 768px) {
       width: 200px;
      }
}

.image-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 60%;
    
}

.container {
    display: flex;
    flex-direction: column;
    border: 6px solid $blue;
    position: relative;
    
    width: 200%;
    text-align: center;
    padding: 15px;
    border-radius: 50px;
    background-color: rgb(233, 233, 233);


    @media screen and (max-width: 768px) {
        padding: 0;
        height: 240px;
        top: -80px;
        width: 350px;
      }

    h1 {
        padding: 5px;
        font-size: 2rem;
        color: $blue;
        width: 100%;
        position: relative;
        bottom: 20px;

        @media screen and (max-width: 768px) {
            padding: 0;
           font-size: 20px;
           position: relative;
           top: 110px;
           
          }
    }

    p {
       color: rgb(83, 83, 83);
      padding: 1rem 2rem;
      text-align: justify;

      @media screen and (max-width: 768px) {
        
        padding: 20px;
       font-size: 14px;
       
      }
    }

     & .title {
        font-size: 0.9rem;
        color: $red;
        text-align: center;
        position: relative;
        top: 20px;
        font-family: 'Preahvihear', sans-serif;

        @media screen and (max-width: 768px) {
            padding: 0;
           font-size: 10px;
          top: 120px;
          }
    }
}






.btn-about {
    
    border-radius: 20px;
    padding: 10px;
    border: none;
    position: relative;
    top: -30px;
    margin-right: 1300px;
    font-size: 1.3rem;
    background-color: $red;
    color: rgb(255, 255, 255);
    transition: transform 0.3s ease-in-out;
   

    @media (max-width: 768px) {
        top: -120px;
      margin-left: 780px;
      font-size: 4px;
      }

    &:hover {
        transform: scale(1.2);
    }
}
