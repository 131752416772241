


.services-container {
width: 100%;
height: 80vh;
background-color: rgb(247, 247, 247);

}

.content-container { 
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
height: 80%;


@media(max-width: 767px) {
    padding-top: 10%;
}
}



.speech-banner {
width: 60%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
border-top-left-radius: 20px;
border-top-right-radius: 20px;
font-family: 'Preahvihear', sans-serif;
color: white;
font-size: 2rem;

a {
    text-decoration: none;
    color: white;
}

&:hover {
    opacity: 70%;
    font-size: 2.5rem;

    @media(max-width: 767px) {
        font-size: 1.5rem;
    }
    }


    @media(max-width: 767px) {
        font-size: 1.2rem;
    }
}

.feeding-banner {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Preahvihear', sans-serif;
    color: white;
    font-size: 2rem;

    a {
        text-decoration: none;
        color: white;
    }

    &:hover {
    opacity: 70%;
    font-size: 2.5rem;

    @media(max-width: 767px) {
        font-size: 1.5rem;
    }

    }

    @media(max-width: 767px) {
        font-size: 1.2rem;
    }
    }



    .myo-banner {
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Preahvihear', sans-serif;
        color: white;
        font-size: 2rem;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
  

        a {
            text-decoration: none;
            color: white;
        }

        &:hover {
            opacity: 70%;
            font-size: 2.5rem;

            @media(max-width: 767px) {
                font-size: 1.5rem;
            }
        
            }

            @media(max-width: 767px) {
                font-size: 1.2rem;
            }
        }


        .banner-link {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            height: 20%;
            width: 60%;

            @media(max-width: 767px) {
                width: 100%;
            }
        }